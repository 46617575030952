import { Box } from '@chakra-ui/react'
import React from 'react'

type Props = {
  title: string
  amount: string
}

export default function AdsenseCell(props: Props) {
  return (
    <Box>
      <Box fontSize=".876rem" mb={1}>
        {props.title}
      </Box>
      <Box fontSize="1.75rem">{props.amount}</Box>
    </Box>
  )
}
