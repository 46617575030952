import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
} from '@chakra-ui/react'
import html2canvas from 'html2canvas'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import './App.css'
import AdsenseCell from './components/AdsenseCell'

function App() {
  const title = '収益ジェネレーター'
  const [yesterdayAmount, setYesterdayAmount] = useState('5000')
  const [monthAmount, setMonthAmount] = useState('100000')
  const imageContainerRef = useRef(null)
  const canvasContainerRef = useRef(null)
  const numberFormat = useMemo(
    () =>
      new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }),
    []
  )

  const formatPrice = useCallback(
    (amount: string) => {
      const numbered = Number(amount)
      if (!isNaN(numbered)) {
        return numberFormat.format(numbered)
      }
      return amount
    },
    [numberFormat]
  )

  async function download() {
    const canvas = await html2canvas(
      imageContainerRef.current as unknown as HTMLElement
    )

    const canvasContainer = canvasContainerRef.current as unknown as HTMLElement
    canvasContainer.appendChild(canvas)

    const link = document.createElement('a')
    link.href = canvas.toDataURL()
    link.target = '_blank'
    link.download = 'income.png'
    document.body.appendChild(link)
    link.click()
    link.remove()
    canvasContainer.innerHTML = ''
  }

  async function share() {
    const canvas = await html2canvas(
      imageContainerRef.current as unknown as HTMLElement
    )

    const canvasContainer = canvasContainerRef.current as unknown as HTMLElement
    canvasContainer.appendChild(canvas)

    const blob = await createBlob(canvas)
    const imageFile = new File(
      [new Uint8Array(await blob.arrayBuffer())],
      'image.png',
      {
        type: 'image/png',
      }
    )

    navigator.share({
      text: '収益ジェネレーター（ばれるから消してね）',
      url: `https://income.alphabrend.com`,
      files: [imageFile],
    } as ShareData)
  }

  function createBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob === null) {
          reject()
          return
        }
        resolve(blob)
      })
    })
  }

  return (
    <Container pt={4}>
      <Heading mb={8}>{title}</Heading>
      <Box>
        <FormControl id="yesterday" mb={3}>
          <FormLabel>昨日</FormLabel>
          <Input
            type="text"
            value={yesterdayAmount}
            onChange={(e) => setYesterdayAmount(e.target.value)}
          />
        </FormControl>
        <FormControl id="month">
          <FormLabel>今月</FormLabel>
          <Input
            type="text"
            value={monthAmount}
            onChange={(e) => setMonthAmount(e.target.value)}
          />
        </FormControl>
      </Box>
      <Box ref={imageContainerRef} py={10}>
        <Box
          backgroundColor="#1565c0"
          color="#fff"
          borderRadius={8}
          padding="16px"
        >
          <Heading as="h2" size="sm" fontWeight={400} py="10px">
            収益
          </Heading>
          <Flex py={2}>
            <Box width="50%">
              <AdsenseCell title="昨日" amount={formatPrice(yesterdayAmount)} />
            </Box>
            <Box width="50%">
              <AdsenseCell title="今月" amount={formatPrice(monthAmount)} />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box>
        <Center>
          <Button type="button" colorScheme="teal" onClick={() => share()}>
            シェア
          </Button>
        </Center>
      </Box>
      <Box mt={4}>
        <Center>
          <Button type="button" colorScheme="teal" onClick={() => download()}>
            ダウンロード
          </Button>
        </Center>
      </Box>
      <Box mt={3}>
        <Center>
          ダウンロードしたら{' '}
          <a
            href={`https://twitter.com/share?url=${encodeURIComponent(
              'https://income.alphabrend.com'
            )}&hashtags=${encodeURIComponent(title)}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <Button type="button" colorScheme="twitter" ml={2}>
              Twitterでシェア
            </Button>
          </a>
        </Center>
        <Center>
          <Box>（ツイートに画像を手動で添付してください）</Box>
        </Center>
      </Box>
      <Box
        ref={canvasContainerRef}
        overflow="hidden"
        style={{ width: '1px', height: '1px' }}
      ></Box>
      <Box mt={8} textAlign="center">
        Created by{' '}
        <Link
          color="teal.500"
          href="https://twitter.com/dala00"
          target="_blank"
          rel="nofollow me"
        >
          @dala00
        </Link>
      </Box>
    </Container>
  )
}

export default App
